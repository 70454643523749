
          @use '@scss/all' as wallaby;
        
.snapshotCard {
  background-color: #f7f7f7;
  border: 2px solid transparent;
  flex: 1;
  padding: 5px 5px 5px 8px;
  position: relative;
}

.snapshotCard.active {
  background-color: #fff;
}

.snapshotCard.sticky {
  background-color: #fff;
  border-color: wallaby.$theme-gray-3;
  border-width: 1px;
  flex: unset;
  justify-content: center;
}

.chooseSnapshotCard {
  background-color: wallaby.$theme-gray-0;
  border: 1px dashed wallaby.$theme-gray-5;
  border-radius: 3px;
  color: wallaby.$theme-gray-6;
  justify-content: center;
}

.pickSideButton {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.snapshotCard:not(:hover) .pickSideButton {
  display: none;
}

.cardContent {
  flex: 1;
}

// hide the hover variant of the edit button
// if not hovering.
.snapshotCard.editButtonHover:not(:hover) .editButton {
  display: none;
}

// hide the edit button if editing
.snapshotCard.isEditing .editButton {
  display: none;
}

.editIcon {
  color: wallaby.$theme-gray-5;
}

// darken the edit button when hovering over it directy
.editButton:hover .editIcon {
  color: wallaby.$theme-dark-5;
}

.snapshotCardTitle {
  line-clamp: 1;
  word-break: 'break-all';

  &[data-manual='true'] {
    font-weight: bold;
  }

  &.currentScript {
    font-style: italic;
    font-weight: unset;
  }

  // We can't make the entire card a link, because then we'd be nesting an edit
  // button inside of it. Instead, we link the title, and make the entire card a
  // target through CSS
  > a {
    // Reset default link styles:
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }

    // Clickshield to take up the size of the card:
    &::before {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }

    // Make any buttons rise above the clickshield
    // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
    .snapshotCard:has(&) button {
      position: relative;
      z-index: 1;
    }

    // Apply hover and focus styles of the link to the card:
    // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
    .snapshotCard:hover:has(&) {
      background-color: var(--mantine-color-gray-1);
    }

    .snapshotCard:has(&:focus-visible) {
      border-color: var(--mantine-primary-color-filled);
    }
  }
}
