
          @use '@scss/all' as wallaby;
        
.snapshotList {
  background-color: #ebebeb;
  height: 100%;
}

.snapshotList_contents {
  padding: 10px;
  padding-right: 15px;
}

.snapshotList_header {
  background-color: #ebebeb;
  border-bottom: 1px solid wallaby.$theme-gray-3;
  z-index: 2;
}

.snapshotList_selectedSection {
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  box-shadow: 0 0 20px #ababab73;
  display: flex;
  padding: 10px 15px 10px 10px;
}

.snapshotList_selectedSection:not(.wrapped) {
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  // this ensures the children are equal width
  // and fill the available space
  > * {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.snapshotList_selectedSection.wrapped {
  flex-direction: column;
  gap: 6px;
}

.snapshotList_emptyButton {
  display: inline-block;
  margin: auto 3px;
}
