/**
 * Our normalize.css styles are more specific around links than the ActionIcon
 * classes so we add the existing Mantine styles scoped to the `a` element.
 */
a.root {
  background: var(--ai-bg, var(--mantine-primary-color-filled));
  color: var(--ai-color, var(--mantine-color-white));

  &:hover,
  &:focus {
    &:where(:not([data-loading], :disabled, [data-disabled])) {
      color: var(--ai-hover-color, var(--ai-color));
      background-color: var(
        --ai-hover,
        var(--mantine-primary-color-filled-hover)
      );
    }
  }
}
