@use '../../wombat-scss/partials/variables' as *;
@use './color-marks';

/*
  Static element numbers (snapshots, printing).  In
  live scripts with editable element numbers, there's
  an input in the same place.
*/
.ProseMirror.is-static,
.ProseMirror[contenteditable='false'] {
  [data-element-number] {
    position: relative;
  }

  [data-element-number]::before {
    // we need to override any block-specific styles for color, bold
    // outline, underline
    color: $d2-dark-gray;
    content: attr(data-element-number);
    font-weight: normal;
    // set in format-specific styles
    left: var(--element-number-left);
    margin-left: -2px;
    position: absolute;
    text-align: end;
    text-decoration: none;
    text-transform: none;
    top: 0;
    width: 50px;
  }
}

/*
  Everything below is copied from the prototype.
  I'm not 100% sure what it all does, but figured we might as well keep it
  the same in both places until we determine what's necessary.
*/
.ProseMirror-hideselection *::selection {
  background: transparent;
}

.ProseMirror-hideselection {
  caret-color: transparent;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor::after {
  border-top: 1px solid #000;
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

/* styles for our remote cursors */
.js-remote-cursor {
  border-right: 2px solid #483d8b;
  margin-left: -1px;
  margin-right: -1px;
}

// styles specific to screen, don't want these in print
@media screen {
  .ProseMirror {
    // full viewport height minus header offset
    min-height: calc(100vh - 50px);
    position: relative;
  }
}

// PAGE
// 8.5 x 11 in (US standard letter size). These styles apply to
// printing and print preview screen styles (both structural and
.o-page {
  font-family: var(--script-font-family), var(--mantine-font-family-monospace);
  font-size: $script-font-size;
  line-height: $script-line-height;
  width: var(--page-width);
}

// Block Elements
// --------------

// Many styles for block elements are configured dynamically at runtime using
// a per-show, data-driven configuration. The following things should NOT be defined here:
//    margin (except to set a default of 0)
//    width
//    lineHeight
//    color  (except to set a default)
//    font stylings such as: uppercase, underline, bold, italic
.o-block-element {
  margin: 0;
  min-height: $script-font-size;
  overflow-wrap: break-word;
  // TODO: use `white-space: break-spaces` when it's ready to be adopted
  // context: https://www.chromestatus.com/feature/4842781306519552
  // not yet supported in FF or Safari
  white-space: pre-wrap;

  // The data driven configs set a standard alignment for the block type but
  // when a user overrides that we use these classes (and need the !important to
  // override the more specific config-driven block styles
  &.is-left-aligned {
    text-align: left !important;
  }

  &.is-right-aligned {
    text-align: right !important;
  }

  &.is-center-aligned {
    text-align: center !important;
  }

  &.has-active-menu {
    background-color: $l3-light-gray;
    box-shadow: 0 0 0 10px $l3-light-gray;
  }

  // ProseMirror inserts a <br> in empty blocks, but we don't do that when
  // printing, so we need a minimum height to ensure they don't collapse.
  // https://discuss.prosemirror.net/t/how-to-preserve-br-tags-in-empty-paragraphs/2051/3
  &:empty {
    height: $script-font-size;
  }
}

// this allows pseudo-elements to be absolutely positioned
// according to their respective block elements
.o-dual-dialogue,
.o-block-element {
  position: relative;
}

// IN-SCRIPT ANCHOR TAGS

.o-block-element a,
.o-block-element a:hover {
  color: #0000f7;
  text-decoration: underline;
}

.o-block-element a:visited {
  color: #551a8b;
}

.o-character-contd {
  padding-left: 3px;
  position: absolute;
  text-transform: initial; // allow for "STEPHEN (cont'd)"
}

@media print {
  .o-block-element a {
    color: var(--link-color, #0000f7);
  }

  // zero out top-margins at tops of pages. For on-screen views this
  // is handled in the script wrappers.
  .o-block-element:first-child,
  .o-dual-dialogue:first-child {
    margin-top: 0;
  }
}

// DUAL_DIALOGUE
// NOTE: all overrides for dual dialogue OTHER than this
// are driven by the data configs
.o-dual-dialogue {
  display: flex;
  justify-content: space-between;
}

// EXCLUDED FROM TIMING
// NOTE: This defines the styles for the marker for excluded from timing
// but the positioning is computed based on the data-driven styles.
// We render this ONLY in the live editor
@media screen {
  .ProseMirror:not(.is-static):not(.is-screenplay) {
    .o-block-element.is-untimed::before {
      align-items: center;
      border-left: 2px dashed $d2-light-gray;
      color: $d3-light-gray;
      content: '\e911';
      display: flex;
      font-family: 'icomoon';
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      height: 100%;
      line-height: 1;
      padding-left: 10px;
      position: absolute;
      text-transform: none;
      width: 3px;
    }

    /* omit the icon for directly adjacent blocks */
    .o-block-element.is-untimed + .o-block-element.is-untimed::before {
      content: '';
    }
  }
}

:root {
  --editor-content-width: calc(
    var(--page-width) - var(--page-left-padding) - var(--page-right-padding)
  );
}

/*
  The formatting of block-specific styles is configured in two places,
  both of which set css variables. These have rather confusing names
  & treat units differently. Here's a primer:

  1. buildFormatStyles.ts uses the script format to generate styles
     that look like these examples

        --slug-margin-left: 20px;
        --bracket-margin-top: 16px;

  2. Our prosemirror schema's toDOM looks for block-level overrides and
     renders style variables that look like this (NOT scoped to block type).
     These are present only if there's a corresponding [data-*] attribute
     on the element.

        --block-left-margin: 4.5; // implied unit of ch
        --block-top-margin: 1; // implied unit of em/16px

     For line height, there are a few hard-coded data- attributes as well
     (e.g. data-line-height="2")


  The blockStyles mixin below creates styles for all blocks that start with
  the ones set for the script format, parameterizing the block name, resulting
  in styles like this:

  .o-slug {
    --current-block-margin-left: var(--slug-margin-left);
  }

  Then, when the inline overrides are set, the value of the VARIABLE
  gets overriden like this:

  &[data-margin-left] {
    --current-block-margin-left: calc(var(--block-left-margin, inherit) * 9.6px);
  }

  which results in this previously set style
    .o-slug { margin-left: var(--slug-margin-left); }
  now having a different value.
*/

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin blockStyles($block) {
  .o-#{$block} {
    --current-block-color: var(--#{$block}-color, inherit);
    --current-block-font-style: var(--#{$block}-font-style, inherit);
    --current-block-font-weight: var(--#{$block}-font-weight, inherit);
    --current-block-line-height: var(--#{$block}-line-height);
    --current-block-margin-left: var(--#{$block}-margin-left);
    --current-block-margin-top: var(--#{$block}-margin-top);
    --current-block-text-align: var(--#{$block}-text-align);
    --current-block-text-decoration: var(--#{$block}-text-decoration, inherit);
    --current-block-text-transform: var(--#{$block}-text-transform, inherit);
    --current-block-width: var(--#{$block}-width);

    --left-gutter: var(--#{$block}-margin-left);
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin dualBlockStyles($block) {
  .o-dual-dialogue .o-#{$block} {
    --current-block-color: var(--#{$block}-dual-color, inherit);
    --current-block-font-style: var(--#{$block}-dual-font-style, inherit);
    --current-block-font-weight: var(--#{$block}-dual-font-weight, inherit);
    --current-block-line-height: var(--#{$block}-dual-line-height);
    --current-block-margin-left: var(--#{$block}-dual-margin-left);
    --current-block-margin-top: var(--#{$block}-dual-margin-top);
    --current-block-text-align: var(--#{$block}-dual-text-align);
    --current-block-text-decoration: var(
      --#{$block}-dual-text-decoration,
      inherit
    );
    --current-block-text-transform: var(
      --#{$block}-dual-text-transform,
      inherit
    );
    --current-block-width: var(--#{$block}-dual-width);
  }
}

@include blockStyles(action);
@include blockStyles(bracket);
@include blockStyles(character);
@include blockStyles(dialogue);
@include blockStyles(dual-dialogue__column);
@include blockStyles(end-of-act);
@include blockStyles(general);
@include blockStyles(new-act);
@include blockStyles(dual-dialogue);
@include blockStyles(parenthetical);
@include blockStyles(scene);
@include blockStyles(slug);
@include blockStyles(transition);
@include dualBlockStyles(character);
@include dualBlockStyles(dialogue);
@include dualBlockStyles(parenthetical);

// Here we find the lowest margin-left for any depth 2 block.
// This is used in pageless mode to reclaim whitespace
.ProseMirror {
  --min-left-margin-script: min(
    var(--action-margin-left),
    var(--bracket-margin-left),
    var(--character-margin-left),
    var(--dialogue-margin-left),
    var(--end-of-act-margin-left),
    var(--general-margin-left),
    var(--new-act-margin-left),
    var(--parenthetical-margin-left),
    var(--scene-margin-left),
    var(--slug-margin-left),
    var(--transition-margin-left),
    var(--dual-dialogue-margin-left)
  );
}

// Base styles for any block element based on the --current-* vars
.o-block-element,
.o-dual-dialogue,
.o-dual-dialogue__column,
.o-dual-dialogue .o-block-element {
  color: var(--current-block-color, inherit);
  font-style: var(--current-block-font-style, inherit);
  font-weight: var(--current-block-font-weight, inherit);
  line-height: var(--current-block-line-height);
  margin-left: var(--current-block-margin-left);
  margin-top: var(--current-block-margin-top);
  text-align: var(--current-block-text-align);
  text-decoration: var(--current-block-text-decoration, inherit);
  text-transform: var(--current-block-text-transform, inherit);

  // in pageless mode, we set --pageless-block-width to 'auto'
  // so if --pageless-block-width is defined, we use that
  // value, otherwise we use the format specific style
  width: var(--pageless-block-width, var(--current-block-width));

  // Per-block margin overrides are rendered by prosemirror with
  // a data-* attribute and a CSS variable (see notes above).
  // We are counting on the fact that anything with the data- attribute
  // will have the corresponding variable set
  &[data-margin-left] {
    --current-block-margin-left: calc(var(--block-left-margin) * 9.6px);
  }

  &[data-block-top-margin] {
    --current-block-margin-top: calc(var(--block-top-margin) * 16px);
  }

  &[data-width] {
    --current-block-width: calc(var(--block-width) * 9.6px);
  }

  // Line heights are simpler per-block overrides, there's just a data-* attribute
  // with several known values and no css vars.
  &[data-line-height='2'] {
    --current-block-line-height: 2;
  }

  &[data-line-height='1.5'] {
    --current-block-line-height: 1.5;
  }

  &[data-line-height='1'] {
    --current-block-line-height: 1;
  }
}

// Positioning variables based on the --current-* vars (used for
// things like comment bubbles, exclude from timing icons, etc)
.o-dual-dialogue,
.o-block-element {
  --page-left-to-block-start: calc(
    var(--current-block-margin-left) + var(--page-left-padding)
  );

  --block-start-to-page-right: calc(
    var(--page-width) - var(--page-left-to-block-start)
  );

  --element-number-left: calc(-1 * var(--page-left-to-block-start) + 31px);
}
