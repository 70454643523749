
          @use '@scss/all' as wallaby;
        
.dropdown {
  overflow: auto;
}

// Most properties on the [.item selector] can be driven through variables, but
// for the ones that can't, we extend the original style with a new `--menu-item-*`
// variable
//
// [.item selector]: https://github.com/mantinedev/mantine/blob/e5dbc7700511c67609e8a75edd72ed3ddd32b350/packages/@mantine/core/src/components/Menu/Menu.module.css#L27-L58
.item {
  /** default: var(--mantine-color-gray-1) */
  --menu-item-hover: var(--mantine-color-gray-2);
  /** default: var(--mantine-font-size-sm) */
  --menu-item-font-size: var(--mantine-font-size-md);
  /** default: var(--mantine-color-dimmed) */
  --menu-color-disabled: var(--mantine-color-gray-6);
  /** default: calc(var(--mantine-spacing-xs) / 1.5); */
  --menu-item-padding-x: 6px;
  /** default: var(--mantine-spacing-sm) */
  --menu-item-padding-y: 6px;

  font-size: var(--menu-item-font-size, var(--mantine-font-size-sm));
  padding: var(--menu-item-padding-x) var(--menu-item-padding-y);

  &:where([data-disabled], :disabled) {
    color: var(--menu-color-disabled, var(--mantine-color-dimmed));
  }

  // TODO [-normalize.css] Remove when no longer overriding font styles
  // See #3558
  &:where(a) {
    color: var(--menu-item-color, var(--mantine-color-text));

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: inherit;

      &:where(:not(:disabled, [data-disabled])) {
        background-color: var(--menu-item-hover, var(--mantine-color-gray-1));
      }
    }
  }
}

.itemSection {
  font-size: var(--mantine-font-size-sm);
}

.label {
  font-weight: 700;
  padding: 6px;
  text-transform: uppercase;
}
