
          @use '@scss/all' as wallaby;
        
.toolbar_button {
  align-items: center;
  background-color: wallaby.$light-white;
  border-radius: 3px;
  color: wallaby.$theme-dark-9;
  display: flex;
  font-size: 15px;
  height: 28px;
  justify-content: center;
  min-width: unset;
  padding: 0 6px;
  position: relative;
  width: 100%;

  &:not(.toolbar_button___disabled) {
    &:hover {
      background-color: wallaby.$theme-gray-2;
    }

    &.toolbar_button___active:hover {
      background-color: wallaby.$theme-violet-0;
      color: wallaby.$theme-violet-5;
    }
  }

  &.toolbar_button___primary:not(.toolbar_button___disabled) {
    background-color: wallaby.$theme-violet-6;
    color: wallaby.$light-white;

    &:hover {
      background-color: wallaby.$theme-violet-7;
    }
  }

  &[data-variant='light'] {
    background-color: var(--mantine-primary-color-0);

    &:hover {
      background-color: var(--mantine-primary-color-1);
    }
  }
}

.toolbar_button___active {
  background-color: wallaby.$theme-violet-0;
  color: wallaby.$theme-violet-5;
}

.toolbar_button___blue {
  background-color: wallaby.$theme-blue-0;

  &:hover {
    background-color: wallaby.$theme-blue-1;
  }
}

.toolbar_button___gray {
  background-color: wallaby.$theme-dark-0;

  &:hover {
    background-color: wallaby.$theme-dark-1;
  }
}

.toolbar_button___green {
  background-color: wallaby.$theme-green-1;

  &:hover {
    background-color: wallaby.$theme-green-2;
  }
}

.toolbar_button___disabled {
  background-color: wallaby.$light-white;
  color: wallaby.$theme-gray-5;
  cursor: default !important;
}

.toolbar_button___mainIcon {
  padding: 0 1px;
}

.toolbar_button___dropdownIcon {
  margin-left: 4px;
}

.toolbar_button___label {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
